import React, { useState } from "react";
import { useSip } from "../../shared/services/sip/SipContext";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useLocation } from "react-router-dom";

const ElevatorsCard = () => {
  const { state: elevator } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [cameraName, setCameraName] = useState("");
  const { startCall } = useSip();

  const openModal = (videoUrl, name) => {
    setSelectedVideoUrl(videoUrl);
    setCameraName(name);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedVideoUrl("");
    setCameraName("");
    setIsModalOpen(false);
  };

  const handleStartCall = () => {
    if (elevator) {
      startCall(elevator.sip_account); // Передача номера лифта
    }
  };

  return (
    <div className="bg-gray-200 dark:bg-gray-800  min-h-full p-4">
      <h2 className="text-2xl font-bold mb-4">{elevator.name}</h2>
      <div className="flex justify-between text-xl p-4 bg-white dark:bg-gray-900 rounded-lg shadow-md">
        <div className="w-1/2 p-4">
          {elevator.camera && (
            <p className="pb-2">
              <span className="font-bold">Адрес:</span> {elevator.camera.name}
            </p>
          )}
          {elevator.model && (
            <p className="pb-2">
              <span className="font-bold">Модель:</span> {elevator.model.name}
            </p>
          )}
          <button
              className="bg-blue-900 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded my-4"
              onClick={handleStartCall}
          >
            Начать звонок
          </button>
        </div>

        <div className="p-4">
          <div
            className="bg-white h-64 rounded-lg shadow-md cursor-pointer"
            onClick={() =>
              openModal(elevator.camera?.stream_embed, elevator.camera?.name)
            }
          >
            <video
              src={elevator.camera?.stream_preview_mp4}
              type="video/mp4"
              className="w-full h-full object-cover rounded-lg"
            >
              Ваш браузер не поддерживает видео.
            </video>
          </div>
        </div>
      </div>

      {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 overflow-hidden">
            <div className="dark:bg-gray-900 flex flex-col pb-4 pr-4 pl-4 pt-2 w-[60%] h-[75%] max-w-[90%] max-h-[90%] rounded-lg overflow-hidden">
              <div className="flex w-full justify-between p-1 items-center">
                <h1 className="text-lg text-center">{elevator.name}: {cameraName}</h1>
                <button onClick={closeModal}>
                  <XMarkIcon className="w-9 h-9 text-gray-300 hover:text-gray-500" />
                </button>
              </div>
              <iframe
                  title="Selected Video"
                  src={selectedVideoUrl}
                  autoPlay
                  className="w-full h-full object-cover rounded-lg"
              />
            </div>
          </div>
      )}
    </div>
  );
};

export default ElevatorsCard;
