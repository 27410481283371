import { createContext, useContext, useEffect, useRef, useState } from "react";
import JsSIP from "jssip";

const SipContext = createContext();

export const useSip = () => {
  return useContext(SipContext);
};

export const SipProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [profile, setProfile] = useState(null);
  const uaRef = useRef(null);
  const remoteAudioRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const [online, setOnline] = useState(false);
  const [callAccepted, setCallAccepted] = useState(false);
  const [clientData, setClientData] = useState({
    id: null,
    name: "Домофон",
  });

  useEffect(() => {
    // if (!profile || !profile.sip_account || !profile.sip_password) {
    //   return;
    // }

    // return () => {
    //   if (sipUserAgent) {
    //     sipUserAgent.stop();
    //   }
    // };
  }, []);

  const disconnected = () => {
    console.error('Disconnected sip server')
    setOnline(false);
  }

  const connectUa = (profile) => {
    setProfile(profile);
    if (uaRef.current) {
      uaRef.current.removeListener("disconnected", disconnected)
      uaRef.current.unregister({
        all: true
      })
      uaRef.current.stop();
      uaRef.current = null;
    }

    const URI = process.env.REACT_APP_SIP_URI;
    const WS_URI = process.env.REACT_APP_SIP_WS_URI;

    console.log(profile)
    const configuration = {
      uri: `sip:${profile?.sip_account}@${URI}`,
      authorization_user: profile?.sip_account,
      password: profile?.sip_password,
      sockets: [new JsSIP.WebSocketInterface(WS_URI)],
    };

    const sipUserAgent = new JsSIP.UA(configuration);
    uaRef.current = sipUserAgent;

    sipUserAgent.on("connected", () => {
      setOnline(true);
    });
    sipUserAgent.on("disconnected", disconnected);

    sipUserAgent.on("newRTCSession", (e) => {
      const session = e.session;
      const request = e.request;

      // if (session !== null) {
      //   e.session.terminate();
      //   return null;
      // }

      if (session.direction === "incoming") {
        const audio = new Audio(process.env.PUBLIC_URL + '/call.mp3');
        audio.play().catch(e => console.error(e))

        console.log(session.remote_identity.display_name);
        console.log(request.headers['X-Deviceid'][0].raw);
        setClientData((prevState) => ({
          ...prevState,
          id: request.headers['X-Deviceid'][0].raw,
          name: session.remote_identity.display_name,

        }));

        setSession(session);
        session.on("accepted", () => {
          console.log("звонок принят");
          setCallAccepted(true);
        });

        session.on("ended", () => {
          setSession(null);
          setCallAccepted(false);
        });

        session.on("confirmed", () => {
          if (remoteAudioRef.current && session.connection) {
            remoteAudioRef.current.srcObject =
                session.connection.getRemoteStreams()[0];
          }
        });

        session.on("failed", () => {
          setSession(null);
        });

        session.on("peerconnection", (e) => {
          const peerconnection = e.peerconnection;
          console.log("peerconnection connected", peerconnection);
          // Добавление удаленных потоков
          peerconnection.ontrack = (trackEvent) => {
            if (trackEvent.track.kind === "video") {
              let videoDom = document.getElementById("sip-video");
              videoDom.srcObject = trackEvent.streams[0];
            }
          };
          // peerconnection.onaddstream = function (e) {
          // remoteAudioRef.current.srcObject = session.stream;
          // };
        });
      }
    });

    sipUserAgent.start();
  }

  const answerCall = () => {
    if (session) {
      session.answer({
        mediaConstraints: { audio: true, video: false },
        rtcAnswerConstraints: {
          offerToReceiveAudio: 1,
          offerToReceiveVideo: 0,
        },
        pcConfig: {
          hackStripTcp: true, // Важно для хрома, чтобы он не тупил при звонке
          rtcpMuxPolicy: "negotiate", // Важно для хрома, чтобы работал multiplexing. Эту штуку обяза>
          iceServers: [],
          iceTransportPolicy: "all",
        },
      });
    }
  };

  const stopCall = () => {
    if (session) {
      session.terminate();
      setSession(null);
    }
  };

  const startCall = (number) => {
    if (session) {
      session.terminate();
      setSession(null);
    }

    navigator.mediaDevices.getUserMedia({ video: true, audio: true });

    let options = {
      eventHandlers: eventHandlers,
      //        'mediaStream': window.localStream,
      pcConfig: {
        hackStripTcp: true, // Важно для хрома, чтобы он не тупил при звонке
        rtcpMuxPolicy: "negotiate", // Важно для хрома, чтобы работал multiplexing. Эту штуку обязательно нужно включить на астере.
        iceServers: [],
        iceTransportPolicy: "all",
      },
      mediaConstraints: {
        audio: true,
        video: false,
      },
      rtcOfferConstraints: {
        iceRestart: true,
        offerToReceiveAudio: true,
        offerToReceiveVideo: false,
      },
    };
    uaRef.current.call(number, options);
  };

  const eventHandlers = {
    connecting: function (e) {
      console.log("call connecting", e);
      setCallAccepted(true);

      // Тут мы подключаемся к микрофону и цепляем к нему поток, который пойдёт в астер
      let peerConnection = session.connection;

      // Получение локального потока и добавление треков в peerConnection
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
          window.localStream = stream;
          document.getElementById("localAudio").srcObject = stream;
          //document.getElementById('localView').srcObject = stream;
          stream.getTracks().forEach((track) => {
            peerConnection.addTrack(track, stream);
          });
        });

      // Обработка входящих треков
      peerConnection.addEventListener("track", (trackEvent) => {
        console.log("Track event:", trackEvent);
        if (trackEvent.track.kind === "audio") {
          if (remoteAudioRef.current) {
            remoteAudioRef.current.srcObject = trackEvent.streams[0];
          }
        } else if (trackEvent.track.kind === "video") {
          let videoDom = document.getElementById("sip-video");
          videoDom.srcObject = trackEvent.streams[0];
        }
      });
    },
    progress: function (e) {
      console.log("progress");
    },
    failed: function (e) {
      console.log("failed", e);
    },
    started: function (e) {
      console.log("started");
    },
    ended: function (e) {
      console.log("ended");
    },
  };

  return (
    <SipContext.Provider
      value={{
        session,
        answerCall,
        online,
        stopCall,
        startCall,
        callAccepted,
        remoteVideoRef,
        clientData,
        connectUa
      }}
    >
      {children}
      <audio ref={remoteAudioRef} autoPlay />
    </SipContext.Provider>
  );
};
