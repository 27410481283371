import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useSip } from "../../shared/services/sip/SipContext";
import { useSelector } from "react-redux";
import axios from "axios";

const CallModal = () => {
  const {
    session,
    answerCall,
    stopCall,
    callAccepted,
    remoteAudioRef,
    clientData,
  } = useSip();
  const [camerasPreview, setCamerasPreview] = useState([]);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchData = async () => {
      if (clientData.id) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_HOST}/v1/admin/devices/${clientData.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCamerasPreview(response.data.data);
        } catch (error) {
          console.error("Error fetching cameras data: ", error);
        }
      }
    };

    fetchData();
  }, [clientData.id, token]);

  const handleAnswer = () => {
    answerCall();
  };

  const handleReject = () => {
    stopCall();
  };

  if (!session) return null;

  return ReactDOM.createPortal(
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 rounded-lg p-8 shadow-xl">
        <h2 className="text-2xl font-bold mb-4">
          Входящий звонок {clientData.name}
        </h2>
        {session && (
          <>
            <audio ref={remoteAudioRef} autoPlay />
            {!callAccepted && Object.keys(camerasPreview).length === 0 && (
              <div className="skeleton-preview w-[800px] h-[450px] bg-gray-600 animate-pulse rounded-lg"></div>
            )}
            {callAccepted && (
              <video
                id="sip-video"
                autoPlay
                className={`w-[800px] pb-6 rounded-lg ${
                  callAccepted ? "" : "hidden"
                }`}
              />
            )}
            {!callAccepted && Object.keys(camerasPreview).length > 0 && (
              <video
                src={camerasPreview?.camera?.stream_preview_mp4 ?? 'https://placehold.co/600x400'}
                autoPlay
                className={`w-[800px] rounded-lg aspect-video ${
                  callAccepted ? "hidden" : ""
                }`}
              />
            )}
          </>
        )}
        <div className="flex justify-center mt-6">
          {!callAccepted && (
            <button
              className="bg-green-900 text-white px-4 py-2 rounded mr-4"
              onClick={handleAnswer}
            >
              Ответить
            </button>
          )}
          <button
            className="bg-red-900 text-white px-4 py-2 rounded"
            onClick={handleReject}
          >
            Завершить
          </button>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default CallModal;
