import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  XMarkIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@heroicons/react/20/solid";
import {VideoPreview} from "../VideoPreview";

const CamerasList = () => {
  const [camerasData, setCamerasData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [cameraName, setCameraName] = useState("");
  const itemsPerPage = 4; // Количество элементов на странице
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/v1/admin/elevators/cameras?limit=${itemsPerPage}&page=${currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setCamerasData(response.data.data);
      } catch (error) {
        console.error("Error fetching cameras data: ", error);
      }
    };

    fetchData();
  }, [token, currentPage]);

  const openModal = (videoUrl, cameraName) => {
    setSelectedVideoUrl(videoUrl);
    setCameraName(cameraName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedVideoUrl("");
    setIsModalOpen(false);
  };

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">Список камер:</h1>
      <div className="min-h-full rounded-lg overflow-hidden">
        <div className="flex flex-wrap">
          {camerasData.map((camera, index) => (
            <div key={index} className="w-1/4 p-2">
              <div className="dark:bg-gray-900 rounded-lg shadow-md p-2">
                <VideoPreview camera={camera} openModal={openModal} />
                <p className="p-2 text-sm">{camera.name}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end">
          <button
            className="p-2 text-center font-bold"
            onClick={prevPage}
            disabled={currentPage === 1}
          >
            <ArrowLeftIcon className="w-6 h-6 hover:text-gray-400 dark:hover:text-gray-400" />
          </button>
          <button
            onClick={nextPage}
            className="p-2 text-center font-bold"
          >
            <ArrowRightIcon className="w-6 h-6 hover:text-gray-400 dark:hover:text-gray-400" />
          </button>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 overflow-hidden">
            <div className="dark:bg-gray-900 flex flex-col pb-4 pr-4 pl-4 pt-2 w-[60%] h-[75%] max-w-[90%] max-h-[90%] rounded-lg overflow-hidden">
              <div className="flex w-full justify-between p-1 items-center">
                <h1 className="text-lg text-center">{cameraName}</h1>
                <button onClick={closeModal}>
                  <XMarkIcon className="w-9 h-9 text-gray-300 hover:text-gray-500" />
                </button>
              </div>
              <iframe
                title="Selected Video"
                src={selectedVideoUrl}
                autoPlay
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CamerasList;
