import React from "react";
import { ElevatorsList } from "../../components/ElevatorsList";

const ElevatorsPage = () => {
  return (
    <div className="bg-gray-200 dark:bg-gray-800 min-h-full">
      <ElevatorsList />
    </div>
  );
};

export default ElevatorsPage;