import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: !!localStorage.getItem("accessToken"), // Проверка наличия токена
  token: localStorage.getItem("accessToken") || null, // Добавляем хранение токена
  profile: null, // Изначально устанавливаем как null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
      localStorage.setItem("accessToken", action.payload ? state.token : null); // Обновляем токен в localStorage
    },
    setToken: (state, action) => {
      state.token = action.payload;
      state.isAuthenticated = !!action.payload && typeof action.payload === 'string'; // Обновляем isAuthenticated
      localStorage.setItem("accessToken", action.payload); // Сохраняем токен в localStorage
    },
    setProfile: (state, action) => {
      console.log(state)
      state.profile = action.payload; // Устанавливаем переданный логин как имя аккаунта
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.profile = null; // Сбрасываем имя аккаунта при выходе
      localStorage.removeItem("accessToken");
    },
  },
});

// Типы экшенов (action types)
export const { setAuthenticated, setToken, setProfile, logout } = authSlice.actions;

export default authSlice.reducer;