import React from "react";
import { Routes, Route } from "react-router-dom";
import { ElevatorsPage } from "../ElevatorsPage";
import { HomePage } from "../HomePage";
import { Sidebar } from "../../components/Sidebar";
import { Header } from "../../components/Header";
import { ElevatorsCard } from "../../components/ElevatorsCard";

const MainPage = () => {

  return (
    <div id="modal-root" className="flex text-dark dark:text-white flex-col min-h-screen ml-48 mt-[56px]">
      <Header />
      <div className="flex flex-1">
        <Sidebar />
        <div className="flex-1">
          <Routes>
            <Route path="/*" element={<HomePage />} />
            <Route path="/elevators/*" element={<ElevatorsPage />} />
            <Route
              path="/elevator/:id"
              element={<ElevatorsCard />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
