import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HomeIcon, ArrowsUpDownIcon } from "@heroicons/react/20/solid";


const Sidebar = () => {
  const [selectedItem, setSelectedItem] = useState('home');

  return (
    <div className="sidebar bg-gray-800 text-white w-48 h-screen fixed top-0 left-0 overflow-y-auto max-h-full max-w-full mt-[56px] shadow-custom">
      <Link
        to="/"
        className={`p-4 flex items-center hover:bg-gray-700 ${
          selectedItem === "home" ? "bg-gray-700" : ""
        }`}
        onClick={() => setSelectedItem("home")}
      >
        <HomeIcon className="h-5 w-5 mr-2" /> {/* Иконка для Главной */}
        <span>Главная</span>
      </Link>
      <Link
        to="/elevators"
        className={`p-4 flex items-center hover:bg-gray-700 ${
          selectedItem === "elevators" ? "bg-gray-700" : ""
        }`}
        onClick={() => setSelectedItem("elevators")}
      >
        <ArrowsUpDownIcon className="h-5 w-5 mr-2" /> {/* Иконка для Лифтов */}
        <span>Лифты</span>
      </Link>
    </div>
  );
};

export default Sidebar;
