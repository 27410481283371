import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/20/solid";

const ElevatorsList = () => {
  const [elevatorsData, setElevatorsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/v1/admin/elevators?limit=${itemsPerPage}&page=${currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setElevatorsData(response.data.data);
      } catch (error) {
        console.error("Error fetching elevators data: ", error);
      }
    };

    fetchData();
  }, [token, currentPage]);

  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  return (
    <div className="min-h-full p-4">
      <h1 className="text-xl font-bold mb-4">Список лифтов:</h1>

      <div className="rounded-lg overflow-hidden shadow-xl">
        <div className="grid grid-cols-4">
          <div className="bg-gray-300 dark:bg-gray-900 p-4 text-center font-bold">ID</div>
          <div className="bg-gray-300 dark:bg-gray-900 p-4 text-center font-bold">Название</div>
          <div className="bg-gray-300 dark:bg-gray-900 p-4 text-center font-bold">Адрес</div>
          <div className="bg-gray-300 dark:bg-gray-900 p-4 text-center font-bold">Модель</div>
        </div>
        <div className="grid grid-cols-1">
          {elevatorsData.map((elevator) => (
            <Link
              key={elevator.id}
              to={`/elevator/${elevator.id}`}
              state={elevator}
              className="grid grid-cols-4 text-center bg-white dark:bg-gray-900 hover:bg-gray-100 dark:hover:bg-gray-800 border-t border-b border-gray-300 dark:border-gray-700"
            >
              <div className="p-4">{elevator.id}</div>
              <div className="p-4">{elevator.name}</div>
              <div className="p-4">{elevator.buildings[0]?.short}</div>
              <div className="p-4">{elevator.model?.name}</div>
            </Link>
          ))}
        </div>
        <div className="bg-gray-300 dark:bg-gray-900 flex justify-end">
          <button
            className="p-4 text-center font-bold"
            onClick={prevPage}
            disabled={currentPage === 1}
          >
            <ArrowLeftIcon className="w-6 h-6 hover:text-gray-800 dark:hover:text-gray-500" />
          </button>
          <button
            className="p-4 text-center font-bold"
            onClick={nextPage}
          >
            <ArrowRightIcon className="w-6 h-6 hover:text-gray-800 dark:hover:text-gray-500" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ElevatorsList;
