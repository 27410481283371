import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthPage } from "./pages/AuthPage";
import { MainPage } from "./pages/MainPage";
import "./shared/styles/global.css";
import { SipProvider } from "./shared/services/sip/SipContext";
import CallModal from "./components/CallModal/CallModal";

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <SipProvider>
      <Router>{isAuthenticated ? <MainPage /> : <AuthPage />}</Router>
      <CallModal />
    </SipProvider>
  );
}

export default App;
