import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout, setProfile } from "../../store/slices/authSlice";
import axios from "axios";
import { useSip } from "../../shared/services/sip/SipContext";

const Header = () => {
  const { online, connectUa } = useSip();
  const [userData, setUserData] = useState(""); // Состояние для хранения данных пользователя
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Состояние для отслеживания состояния меню
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/v1/admin/users/profile`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const userData = response.data.data;
        console.log(userData)
        connectUa(userData)
        setProfile(userData)
        setUserData(userData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUserData(); // Вызываем функцию для получения данных пользователя при монтировании компонента
  }, [token]); // Зависимость token для использования в useEffect

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen); // Изменяем состояние меню при нажатии на имя пользователя
  };

  const handleLogout = () => {
    dispatch(logout());
    // Дополнительные действия после выхода из приложения
  };

  const handleContentClick = () => {
    setIsMenuOpen(false); // Закрываем меню при клике на контент
  };

  return (
    <>
      {isMenuOpen && <div className="fixed top-0 left-0 w-full h-full bg-black opacity-0 z-10" onClick={handleContentClick}></div>}
      <header className="bg-black text-white py-2 h-[56px] fixed top-0 left-0 w-full flex items-center justify-between z-20">
        <div></div>
        <div className="flex items-center">
          {online ? (
            <>
              <div className="h-3 w-3 rounded-full bg-green-500 mr-2"></div>
              <span>Звонки подключены</span>
            </>
          ) : (
            <>
              <div className="h-3 w-3 rounded-full bg-red-500 mr-2"></div>
              <span>Звонки отключены</span>
            </>
          )}
        </div>
        <div className="flex pr-4 items-center relative">
          <div onClick={handleMenuToggle} className="flex items-center cursor-pointer">
            <img src={userData.avatar} alt="User Avatar" className="w-10 mr-2 rounded-full" />
            <span>{userData.name}</span>
          </div>
          {isMenuOpen && (
            <div className="absolute top-full w-[200px] right-0 bg-black p-5 shadow-md rounded-b-lg z-30">
              <button className="pb-2 w-full dark:bg-gray-900 dark:hover:bg-gray-800 p-2 rounded-lg" onClick={handleLogout}>Выйти</button>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
