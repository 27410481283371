import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "../../store/slices/authSlice";
import { MainPage } from "../MainPage";
import axios from "axios";

const AuthPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const CLIENT_SCR = process.env.REACT_APP_CLIENT_SECRET;
  const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/v1/admin/auth/login`,
        {
          client_id: CLIENT_ID,
          client_secret: CLIENT_SCR,
          username: username,
          password: password,
        }
      );
      const token = response.data.access_token;
      // const accountName = username; // Используем логин пользователя как имя аккаунта
      dispatch(setToken(token)); // Установка токена в Redux хранилище
      // dispatch(setAccountName(accountName)); // Установка имени аккаунта в Redux хранилище
    } catch (error) {
      console.log(error); // Выводим ошибку целиком для дальнейшего анализа
      alert("Неверный логин или пароль");
    }
  };

  if (isAuthenticated) {
    return <MainPage />;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 dark:bg-gray-900">
      <div className="max-w-md w-full bg-white p-8 rounded shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Авторизация</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label
              htmlFor="login"
              className="block text-sm font-medium text-gray-700"
            >
              Логин
            </label>
            <input
              type="text"
              id="login"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="mt-1 block w-full p-2 rounded border border-gray-500 shadow-sm sm:text-sm"
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Пароль
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full p-2 rounded border border-gray-300 shadow-sm sm:text-sm"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-indigo-500 text-white rounded py-2"
          >
            Войти
          </button>
        </form>
      </div>
    </div>
  );
};

export default AuthPage;
