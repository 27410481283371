import React from "react";
import { ElevatorsList } from "../../components/ElevatorsList";
import CamerasList from "../../components/CamerasList/CamerasList";

const HomePage = () => {

  return (
    <div className="dark:bg-gray-800 bg-gray-100 min-h-full p-4">
      <h1 className="text-2xl font-bold mb-4">Главная</h1>
    <CamerasList />
    <ElevatorsList />
    </div>
  );
};

export default HomePage;
